
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
// ref
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Field,
    // eslint-disable-next-line vue/no-unused-components
    Form,
    // eslint-disable-next-line vue/no-unused-components
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoadingLogin = ref(false);
    const form = ref({
      user_partner_tel: "",
      user_partner_password: "",
    });
    // const submitButton = ref<HTMLButtonElement | null>(null);
    // //Create form validation object
    // const login = Yup.object().shape({
    //   email: Yup.string().email().required().label("Email"),
    //   password: Yup.string().min(4).required().label("Password"),
    // });
    // //Form submit function
    // const onSubmitLogin = (values) => {
    //   // Clear existing errors
    async function login() {
      store.dispatch(Actions.LOGOUT);
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     // Activate indicator
      //     submitButton.value.setAttribute("data-kt-indicator", "on");
      //   }
      //   // Dummy delay
      //   setTimeout(() => {
      //     // Send login request
      isLoadingLogin.value = true;
      let payload = JSON.parse(JSON.stringify(form.value));
      if (
        payload.user_partner_tel.length > 0 &&
        payload.user_partner_tel.charAt(0) == "0"
      ) {
        payload.user_partner_tel = payload.user_partner_tel.substring(1);
      }
      let result = await store.dispatch(Actions.LOGIN, payload);
      isLoadingLogin.value = false;
      if (result == true) {
        Swal.fire({
          text: "You have successfully logged in!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          // Go to page after successfully login
          router.push({ name: "dashboard" });
        });
      } else {
        // const [error] = Object.keys(store.getters.getErrors);
        Swal.fire({
          text: store.getters.getErrors,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    }
    //     //Deactivate indicator
    //     submitButton.value?.removeAttribute("data-kt-indicator");
    //     // eslint-disable-next-line
    //     submitButton.value!.disabled = false;
    //   }, 2000);
    // };
    return {
      form,
      login,
      isLoadingLogin,
      // onSubmitLogin,
      // login,
      // submitButton,
    };
  },
});
